<template>
  <v-container
    class="d-flex flex-column flex-nowrap px-0 pt-0 mx-0"
  >
    <div>
      <v-row no-gutters>
        <v-col
          v-show="$route.name === 'WorkbenchViewCaseModels'"
          cols="6"
          :style="`height: ${$vuetify.breakpoint.width <= 1500 ? ($vuetify.breakpoint.height - 400).toString() + 'px' : ($vuetify.breakpoint.height - 345).toString() + 'px'}`"
        >
          <v-card
            flat
            class="mr-1"
            style="overflow-y: auto; overflow-x: hidden;"
            :style="`height: ${$vuetify.breakpoint.width <= 1500 ? ($vuetify.breakpoint.height - 400).toString() + 'px' : ($vuetify.breakpoint.height - 345).toString() + 'px'}`"
            outlined
          >
            <v-toolbar
              dense
              flat
              height="45"
              color="lightBackground"
              style="position: sticky; top: 0px; z-index: 1;"
            >
              <v-subheader
                class="ml-n7"
              >
                {{ $t('cases|templates') }}
              </v-subheader>
              <v-spacer />
              <v-btn
                v-if="(account.accountType === 'lawyer' || account.accountType === 'god') && $vuetify.breakpoint.width > 1500"
                x-small
                rounded
                color="primary"
                class="mr-4 ml-4"
                @click="addWorkbenchTemplate"
              >
                <v-icon
                  x-small
                  left
                >
                  mdi-plus
                </v-icon>
                {{ $t('actions|add_new_template') }}
              </v-btn>
              <v-btn
                v-if="(account.accountType === 'lawyer' || account.accountType === 'god') && $vuetify.breakpoint.width <= 1500"
                x-small
                fab
                color="primary"
                class="mr-4 ml-4"
                @click="addWorkbenchTemplate"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row
                v-if="account.accountType === 'lawyer' || account.accountType === 'god'"
                align="center"
              >
                <div
                  v-for="template in currentWorkbench.templates.slice().reverse()"
                  :key="template._id"
                  class="mx-2 my-4"
                >
                  <TemplateCardCaseModels
                    :template="template"
                    :workbench="currentWorkbench"
                    :current-case-form="currentCaseForm"
                    @selectTemplate="selectTemplate(template)"
                    @updateTemplateName="updateTemplateName"
                    @delete="deleteWorkbenchTemplate(currentWorkbench, template)"
                  />
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="account.accountType === 'lawyer' || account.accountType === 'god'"
          v-show="$route.name === 'WorkbenchViewCaseModels'"
          cols="6"
        >
          <v-card
            flat
            outlined
            class="ml-1"
            :style="`height: ${$vuetify.breakpoint.width <= 1500 ? ($vuetify.breakpoint.height - 400).toString() + 'px' : ($vuetify.breakpoint.height - 345).toString() + 'px'}`"
          >
            <v-toolbar
              dense
              flat
              height="45"
              color="lightBackground"
              style="position: sticky; top: 0px; z-index: 1;"
            >
              <v-subheader style="white-space: normal;">
                {{ $t('mixed|required_workbench_documents') }}
              </v-subheader>
              <template>
                <div class="text-center">
                  <v-dialog
                    v-model="infoDialog"
                    width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="info"
                        dark
                        v-bind="attrs"
                        class="ml-n3 mt-n1"
                        v-on="on"
                      >
                        mdi-information-variant
                      </v-icon>
                    </template>

                    <v-card>
                      <v-card-title class="text-h6 grey lighten-2">
                        {{ $t('case_models|assigned_to_workbench_documents') }}
                      </v-card-title>

                      <v-card-text class="mt-4">
                        {{ $t('expressions|assigning_documents_info') }}
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="infoDialog = false"
                        >
                          {{ $t('actions|close') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </template>
              <v-spacer />
              <v-btn
                v-if="$vuetify.breakpoint.width > 1500"
                x-small
                rounded
                color="primary"
                class="ml-4 mr-4"
                @click="assignDocDialog = true"
              >
                <v-icon
                  x-small
                  left
                >
                  mdi-link
                </v-icon>
                {{ $t('actions|assign_document') }}
              </v-btn>
              <v-btn
                v-if="$vuetify.breakpoint.width <= 1500"
                x-small
                fab
                color="primary"
                class="ml-4 mr-4"
                @click="assignDocDialog = true"
              >
                <v-icon>
                  mdi-link
                </v-icon>
              </v-btn>
            </v-toolbar>
            <div
              class="d-flex"
            >
              <WorkbenchDocumentList
                id="document-list-container"
                :documents="currentWorkbench.requiredDocuments"
                :drag-area="dragArea"
                @delete-required-doc="deleteRequiredWbDoc"
                @newWorkbenchDocumentCaseModel="onNewWorkbenchDocumentCaseModel"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <router-view />
      <v-dialog
        v-model="assignDocDialog"
        max-width="550"
      >
        <AssignWorkbenchDocuments
          :current-case-form="currentCaseForm"
          :current-workbench="currentWorkbench"
          @close-assign-dialog="assignDocDialog = false"
          @apply-doc-suggestions="applyDocSuggestions"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import caseModelWorkbenchMixin from './CaseModelMixins/caseModelWorkbenchMixin'

export default {
  mixins: [caseModelWorkbenchMixin]
}
</script>
